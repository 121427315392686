import { takeEvery, call, put } from "redux-saga/effects";
import { ActionTypes } from "../constants";
import utils from "../utils";
import notification from "../utils/notifications";
import Api from "../api";
import { history } from 'shared/routes/urlLocations';
import setUnloginedUserType from "../utils/setUnloginedUserType";

export function* confirmationEmail(action) {
  try {
    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_STARTED });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { token, values: { password, confirmPassword } } = action;
    const { request, response, status } = yield call(
      Api.Registration.confirmationEmail,
      `${utils.config.apiUserUrl}/public/password/set/${token}`,
      {
        password,
        passwordConfirmation: confirmPassword
      }
    );

    if (status === "success") {
      yield call(notification, {
        type: "success",
        data: { code: 3 },
        staticNotification: true,
      });
      yield call(setUnloginedUserType, response.userType);
      yield history.push("/login");
    } else if (request.status && request.status >= 500) {
      yield call(notification, {
        type: "error",
        data: "An error occurred. Please, try again"
      });
    } else {
      yield call(notification, {
        type: "error",
        data: response.code,
      });
    }
  } catch (error) {
    yield call(notification, {
      type: "error",
      data: error.message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_FINISHED });
  }
}

export function* checkConfirmToken({ token }) {
  try {
    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_STARTED });

    const { response, status } = yield call(
      Api.Registration.checkConfirmToken,
      `${utils.config.apiUserUrl}/public/${token}`
    );
    if (status === "error") {
      yield call(notification, {
        type: "error",
        data: response
      });
      yield history.push("/login");
    }
  } catch (e) {
    yield call(notification, {
      type: "error",
      data: e.message
    });
  } finally {
    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_FINISHED });
  }
}

export function* watchConfirmationEmail() {
  yield takeEvery(ActionTypes.CONFIRM_EMAIL_REQUEST, confirmationEmail);
}

export function* watchCheckConfirmToken() {
  yield takeEvery(ActionTypes.CHECK_CONFIRM_EMAIL_TOKEN_REQUEST, checkConfirmToken);
}
