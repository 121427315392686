import { takeEvery } from 'redux-saga/effects';
import { ActionTypes as ActionTypesShared } from 'shared/constants/';
import Api from '../api';
import patternSaga from './patternSaga';
import utils from 'shared/utils';
import { getSubscriptionPrice } from 'shared/actions/paymentsActions';
import { history } from 'shared/routes/urlLocations';
import { userHandlingStrategicPartners } from 'shared/routes/urlLocations';

const getUserPlatformCommissionConfig = {
  requestFunction: Api.User.getUserPlatformCommission,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/escrow-commission`
};

const putUserPlatformCommissionConfig = {
  requestFunction: Api.User.putUserPlatformCommission,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/escrow-commission`,
  onSuccess: {
    trigAction: { type: ActionTypesShared.GET_USER_PLATFORM_COMMISSION },
    notification: {
      staticNotification: true,
      data: {
        code: '0'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const getProjectPublishingPriceConfig = {
  requestFunction: Api.User.getProjectPublishingPrice,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/project-publishing-price`
};

const setProjectPublishingPriceConfig = {
  requestFunction: Api.User.setProjectPublishingPrice,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/project-publishing-price`,
  onSuccess: {
    trigAction: { type: ActionTypesShared.GET_PROJECT_PUBLISHING_PRICE },
    notification: {
      staticNotification: true,
      data: {
        code: '0'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

const getSubscriptionPriceConfig = {
  requestFunction: Api.User.getSubscriptionPrice,
  requestUrl: `${utils.config.apiUserUrl}/subscriptions`
};

const setSubscriptionPriceConfig = {
  requestFunction: Api.User.setSubscriptionPrice,
  requestUrl: `${utils.config.apiUserUrl}/subscriptions`,
  onSuccess: {

    trigAction: values => {

      let subDomain = values && values.subscriptions && values.subscriptions[0].subDomain
        ? values.subscriptions[0].subDomain
        : null;
      return getSubscriptionPrice(subDomain);
    },
    redirectTo: (response, values) => {
      values
        && values.subscriptions
        && values.subscriptions[0].subDomain
        && history.push(userHandlingStrategicPartners);
    },
    notification: {
      staticNotification: true,
      data: {
        code: '0'
      }
    }
  },
  onFailure: {
    notification: true
  }
};

export default function* watchUserPlatform() {
  yield takeEvery(
    ActionTypesShared.GET_USER_PLATFORM_COMMISSION,
    patternSaga,
    getUserPlatformCommissionConfig
  );
  yield takeEvery(
    ActionTypesShared.PUT_USER_PLATFORM_COMMISSION,
    patternSaga,
    putUserPlatformCommissionConfig
  );
  yield takeEvery(
    ActionTypesShared.GET_PROJECT_PUBLISHING_PRICE,
    patternSaga,
    getProjectPublishingPriceConfig
  );
  yield takeEvery(
    ActionTypesShared.SET_PROJECT_PUBLISHING_PRICE,
    patternSaga,
    setProjectPublishingPriceConfig
  );
  yield takeEvery(
    ActionTypesShared.GET_SUBSCRIPTION_PRICE,
    patternSaga,
    getSubscriptionPriceConfig
  );
  yield takeEvery(
    ActionTypesShared.SET_SUBSCRIPTION_PRICE,
    patternSaga,
    setSubscriptionPriceConfig
  );
}
